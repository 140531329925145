<template>
  <div>
    暂无权限请联系客服
  </div>
</template>

<script>
export default {
  name: '502'
}
</script>

<style scoped>

</style>
