<template>
  <exception-page type="403" />
</template>

<script>
import { ExceptionPage } from '@/components'

export default {
  components: {
    ExceptionPage
  }
}
</script>

<style scoped>

</style>
